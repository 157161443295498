<template>
	<div class="city">
		<div class="cleft">
			<div class="chose">
				<div class="title">
					<img src="../assets/index/active33.png" alt="">
					<h2>趣·旅行</h2>
				</div>
				<div class="selectlist">
					<div class="sitem">
						<div class="stitle">活动特色</div>
						<div class="scontent">
							<ul :class="[{'ulheight':h1}]">
								<li v-for="(item,i) in list1" :key="i" @click="chose(item,1)">
									· {{item}}
								</li>
							</ul>
						</div>
						<div class="smore" @click="h1=!h1">
							<img src="../assets/public/sjx.png" alt="">
							<span>更多</span>
						</div>
					</div>
					<div class="sitem">
						<div class="stitle">目的地/景点</div>
						<div class="scontent">
							<ul :class="[{'ulheight':h2}]">
								<li v-for="(item,i) in list2" :key="i" @click="chose(item,2)">
									· {{item}}
								</li>
							</ul>
						</div>
						<div class="smore" @click="h2=!h2">
							<img src="../assets/public/sjx.png" alt="">
							<span>更多</span>
						</div>
					</div>
					<div class="sitem">
						<div class="stitle">出发城市 </div>
						<div class="scontent">
							<ul :class="[{'ulheight':h3}]">
								<li v-for="(item,i) in list3" :key="i" @click="chose(item,3)">
									· {{item}}
								</li>
							</ul>
						</div>
						<div class="smore" @click="h3=!h3">
							<img src="../assets/public/sjx.png" alt="">
							<span>更多</span>
						</div>
					</div>
					<div class="sitem">
						<div class="stitle">活动天数</div>
						<div class="scontent">
							<ul>
								<li v-for="(item,i) in list4" :key="i" @click="chose(item,4)">
									· {{item}}天
								</li>
							</ul>
						</div>
						<!-- <div class="smore" @click="h1=!h1">
							<img src="../assets/public/sjx.png" alt="">
							<span>更多</span>
						</div> -->
					</div>
					<div class="sitem">
						<div class="stitle">活动时间</div>
						<div class="scontent">
							<ul :class="[{'ulheight':h5}]">
								<li v-for="(item,i) in list5" :key="i" @click="chose(item,5)">
									· {{item}}
								</li>
							</ul>
						</div>
						<!-- <div class="smore">
							<img src="../assets/public/sjx.png" alt="">
							<span>更多</span>
						</div> -->
					</div>
					<div class="sitem">
						<div class="stitle">活动品牌</div>
						<div class="scontent">
							<ul :class="[{'ulheight':h3}]">
								<li @click="chose('趣游专线',6)">
									· 趣游专线
								</li>
								<li @click="chose('跟团游',6)">
									· 跟团游
								</li>
							</ul>
						</div>
						
					</div>
					<div class="sitem">
						<div class="stitle">您已选择</div>
						<div class="scontent">
							<ul>
								<ul>
									<li class="selected"
									v-for="(item,i) in selectlist" :key="i">
										<div>{{item.name | addDay}}</div>
										<img src="../assets/other/close.png" @click="close(i)">
									</li>
								</ul>
							</ul>
						</div>
						<div class="smore" @click="clearall">
							<img src="../assets/other/clear.png" alt="">
							<span>清空</span>
						</div>
					</div>
				</div>
			</div>
			<triverlList :goodlist="goodlist" @handleCurrentChange="handleCurrentChange" :total="total" :pageSize="form.pageSize"></triverlList>

			<div style="height: 20px;"></div>
		</div>

		<div class="cright">
			<h3>活动集锦</h3>
			
			<div v-for="(item,i) in activelist" :key="i" @click="toactive(item.id)" class="img">
				<img :src="item.attachment" alt="">
				<div class="bg"></div>
				<p>{{item.title}}</p>
			</div>
			<!-- 分页 -->
			<div id="page" v-if="total2>0">
				<!-- <img src="../assets/other/pleft.png" @click="changepage()">
				<p>1/1</p>
				<img src="../assets/other/pright.png" alt=""> -->
				<el-pagination
				small
				layout="prev, pager, next"
				:total="total2"
				@current-change="handleCurrentChange2"
				:page-size="form2.pageSize"
				>
				</el-pagination>
			</div>
		</div>

	</div>
</template>

<script>
import {attributeListAPI,goodsListAPI,takeCollectionList} from "@/api/active"
import { mapState } from 'vuex'
import triverlList from "@/components/com/triverl"
export default {
	components:{
		triverlList
	},
  data () {
    return {
      list1: [],//活动特色
      h1: true,
      list2: [],//目的地
      h2: true,
      list3: [],//出发城市
      h3: true,
	  list4: [],//天
      h4: true,
	  list5: [],//时间
      h5: true,
      
      selectlist: [],
	  form:{		 
		  t_days:null,
		  daes:null,
		  brand_id:null,
		  buy_date:null,	
		  features:null,
		  origin_name:null,
		  pid:6,
		  page:1,
		  pageSize:10
	  },
	  goodlist:[],
	  activelist:[],
	  total:1,
	  total2:1,
		form2:{
			pageSize:6,
			page:1,
			pid:6
		}
	  
    }
  },
  created(){
	  this.getattributeList()
	  this.getgoodsList()
	  this.getactivelist()
  },
  
  methods: {
	   handleCurrentChange: function(currentPage){
            this.form.page=currentPage
            this.getgoodsList()         
       },
	    handleCurrentChange2: function(currentPage){
           this.form2.page=currentPage
            this.getactivelist()
       },
	  clearall(){
		  this.selectlist=[]
		  var a=this.form.pid
		  var b=this.form.page
		  var c=this.form.pageSize
		 
			this.form={		 
				 pid:a,
				page:b,
				pageSize:c
			}
		  this.getgoodsList()
	  },
    close (i) {
        this.selectlist.splice(i, 1)
	    var a=this.form.pid
		  var b=this.form.page
		  var c=this.form.pageSize
		 
			this.form={		 
				 pid:a,
				page:1,
				pageSize:c
			}
	    this.getgoodsList()
    },
    chose (item,i) {
		this.form.page=1
		var obj={id:i,name:item}
		var flag=true
		for (var j=0;j<this.selectlist.length;j++) {
			if (this.selectlist[j].id==obj.id) {
				 this.selectlist.splice(j,1,obj)
				 flag=false		
			}		
		}
		if (flag) {
			this.selectlist.push(obj)
		}	
		console.log(this.selectlist)	
		this.getgoodsList()		 
    },
	getattributeList(){
		attributeListAPI({pid:this.form.pid}).then(res=>{
			this.list1=res.cateView.features
			this.list2=res.cateView.daes
			this.list3=res.cateView.origin_name
			this.list4=res.cateView.t_days
			this.list5=res.cateView.buy_date
		})
	},
	getactivelist(){
		takeCollectionList(this.form2).then(res=>{
			this.activelist=res.cateList
			this.total2=res.rowsCount
		})
	},
	toactive(id){
		this.$router.push({ path: '/imginfo',query:{id} })
	},
	getgoodsList(){
		for (let index = 0; index < this.selectlist.length; index++) {
			let selt=this.selectlist[index]			
			switch (selt.id) {
				case 1:
					this.form.features=selt.name
					break;
				case 2:
					this.form.daes=selt.name
					break;
				case 3:
					this.form.origin_name=selt.name
					break;
				case 4:
					this.form.t_days=selt.name
					break;
				case 5:
					this.form.buy_date=selt.name
					break;
				case 6:
					this.form.brand_id=selt.name=="跟团游" ? 2 : 1
					break;	
				default:
				
					break;
			}
		}
		console.log(this.form)
		goodsListAPI(this.form).then(res=>{
			this.goodlist=res.cateList
			this.total=res.rowsCount
		})
	},
	todetails(id){
		 this.$router.push({ path: '/details',query:{id} })
	}
  }

}
</script>

<style lang="scss" scoped="scoped">
.city{
	width: 1200px;
	margin: 20px auto;
	display: flex;
	justify-content: space-between;
}
.chose{
	position: relative;
	width:930px;
	// height: 360px;
	box-sizing: border-box;
	background: #fff url('../assets/other/bgcity.png') no-repeat;

	// background-size: 100%;
	border-radius: 10px;
	padding: 15px;

	.title {
		display: flex;
		align-items: center;
		margin-bottom: 20px;

		h2 {
			font-size: 18px;
			color: #6E380C;
		}

		img {
			width: 20px;
			height: 20px;
			margin-right: 10px;
		}
	}

	.selectlist{
		
		.sitem{
			padding: 5px 30px;
			display: flex;
			.stitle{
				width: 100px;

				font-size: 14px;

				font-weight: 600;
				text-align: left;
				color: #333333;
				line-height: 32px;
			}
			.scontent{
				flex: 1;
				.ulheight{
					height:30px;
					transition: height 6.6s linear;
				}
				ul{
					display: flex;
					flex-wrap: wrap;

					overflow: hidden;
					transition: height 6.6s linear;
					li{
						margin: 0 16px;
						font-size: 13px;
						color: #333333;
						line-height: 30px;
						cursor: pointer;
					}
					.selected{
						display: flex;
						align-items: center;
						div{
							height:25px ;
							opacity: 0.9;
							background: #ff7c00;
							border-radius: 2px 0 0 2px;
							line-height: 25px;
							padding:  0 10px;
							color: #fff;
						}
						img{
							width: 25px;
							height: 25px;
						}
						margin:5px 15px;
					}
				}
			}
			.smore{
				cursor: pointer;
				width: 50px;
				// background-color: red;
				font-size: 12px;
				color: #333333;
				line-height: 30px;
				img{
					width:10px;
					margin-right: 5px;
				}

			}
		}
	}

}

.travellist{
	background-color: #fff;
	border-radius: 10px;
	width:930px;
	min-height: 500px;
	margin-top: 20px;
	padding: 20px 20px;
	box-sizing: border-box;
	.tourism{
		display: flex;
		margin-bottom: 20px;
		.img{
			position: relative;
			border-radius: 5px;
			width: 330px;
			height: 160px;
			overflow: hidden;
			.image{
				width: 100%;
				min-height: 160px;
				border-radius: 5px 5px 0 0;
			}
			.day{
				position: absolute;
				top: -1px;
				left: 0;
				width: 55px;
				background:rgba(0,0,0,0.4) url(../assets/index/day.png) no-repeat;
				background-size: 100%;
				border-radius: 5px 0 0 0 ;
				p{
					color: #fff;
					text-align: center;
					font-size: 12px;
					line-height: 35px;
					span{
						color: #fff;
						font-weight: 500;
						font-size: 17px;
					}
				}
				.d2{
					line-height: 24px;
					font-size: 11px;
				}
			}

			.adress{
				position: absolute;
				bottom: 5px;
				left: 5px;
				padding: 5px 8px;
				font-size: 11px;
				color: #fff;
				background-color: rgba(0,0,0,0.4);
				border-radius:3px;
			}
			.join{
				position: absolute;
				bottom: 5px;
				right: 5px;
				padding: 5px 8px;
				font-size: 11px;
				color: #fff;
				background-color: rgba(0,0,0,0.4);
				border-radius:3px;
			}
		}

		.content{
			flex: 1;
			padding-left: 20px;
			h3{
				font-size: 17px;
				font-weight: 600;
				line-height: 28px;
				height: 55px;
				// background-color: red;
			}
			.label{
				ul{
					display: flex;
					margin-top: 12px;
					li{
						border: 1px solid #ff7c00;
						border-radius: 2px;
						font-size: 12px;
						padding: 0 10px;
						color: #ff7c00;
						line-height: 23px;
						margin-right:15px ;
					}
				}
			}
			.flex-between{
				margin-top: 15px;
				.p{
					text-align: right;
					padding-right: 20px;
					width: 130px;
					color: #ff4c12;
					font-size: 12px;
					margin-top: 5px;
					span{
						font-size: 22px;
						font-weight: 600;
						text-align: left;
						color: #ff4c12;
					}
				}
				.time{
					flex: 1;
					// padding: 10px;
					display: flex;
					>div{
						flex: 1;
						p{
							font-size: 13px;

							color: #666;
							line-height: 25px;

						}
						.orange{
							color:#FF4C12
						}
					}
				}
			}

		}
	}
	.page{
		width: 120px;

		display: flex;
		height: 25px;
		margin: 45px auto 15px;
		p{

			margin: 0 8px;
			font-size: 14px;
			text-align: center;
			color: #333333;
			line-height: 25px;
		}
	}
}

.cright{
	width: 240px;
	h3{
		font-size: 16px;
		font-weight: 600;
		color: #333333;
		line-height: 35px;
		letter-spacing: 1px;
	}
	
	.img{
		margin-top: 10px;
		border-radius: 4px;
		img{
			width: 240px;
			margin-top: 10px;
			border-radius: 4px;
		}
		p{
			text-align: center;
		}
		
	}
	.page{
		width: 110px;

		display: flex;
		height: 25px;
		margin: 25px auto 15px;
		p{
			margin: 0 8px;
			font-size: 14px;
			text-align: center;
			color: #333333;
			line-height: 25px;
		}
		img{
			height: 25px;
			width: 25px;
			margin: 0;
		}
	}
}
</style>
<style lang="scss">
#page{
	margin: 80px auto 30px;
	display: flex;
	justify-content: center;

	.el-pager .active{
		background-color: #ff7c00 !important;
	}
	.el-pager li.active{
		color: #fff;
	}
	.number:hover{
		color: #ff7c00;
	}
}
</style>