import myRequest from '../http/index.js'
// 活动板块下的筛选
export function attributeListAPI(params){
    return myRequest({
        url:"index.php/index/store/attributeList",         
        params,
        method:"get"
      })
}
// 活动板块下赛选活动列表
export function goodsListAPI(params){
    return myRequest({
        url:"index.php/index/store/goodsIndex",         
        params,
        method:"post"
      })
}
// 接口：活动集锦
export function takeCollectionList(params){
  return myRequest({
      url:"index.php/index/article/takeCollectionList",         
      params,
      method:"get"
    })
}