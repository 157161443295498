import { render, staticRenderFns } from "./quTravel.vue?vue&type=template&id=b0a2bb26&scoped=true&"
import script from "./quTravel.vue?vue&type=script&lang=js&"
export * from "./quTravel.vue?vue&type=script&lang=js&"
import style0 from "./quTravel.vue?vue&type=style&index=0&id=b0a2bb26&prod&lang=scss&scoped=scoped&"
import style1 from "./quTravel.vue?vue&type=style&index=1&id=b0a2bb26&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0a2bb26",
  null
  
)

export default component.exports